import { colors } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useRegisterStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    paddingBottom: 80,
    paddingTop: 80
  },
  logoWrapper: {
    marginBottom: '30px'
  }
}));

export const useLoginStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
}));

export const loginSx = {
  icon: {
    backgroundColor: theme => theme?.palette?.primary?.main,
    color: theme => theme.palette.common.white,
    borderRadius: theme => theme.shape.borderRadius,
    padding: theme => theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: 9,
    height: 64,
    width: 64
  },
  content: { px: 4, pt: 3, pb: 3 },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    marginTop: '10px',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  }
};
export const useTokenStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}));

export const useToolBarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    height: 52,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  sortButton: {
    textTransform: 'none',
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  },
  menu: {
    marginLeft: '1%',
    marginTop: '2.9%',
    width: 256,
    maxWidth: '100%'
  },
  menuActive: {
    backgroundColor: theme.palette.action.selected
  },
  listItem: {
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  filterActive: {
    color: colors.amber[400]
  },
  smallIcon: {
    fontSize: '1.4rem',
    padding: 0
  }
}));

export const navBarSx = {
  tabPanel: {
    padding: 0
  }
};

const baseUseStyles = makeStyles(() => ({
  root: {}
}));

export default baseUseStyles;
