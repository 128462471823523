/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListSubheader,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import ContactLink from 'src/components/ContactLink';
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';
import ManualApprovedGuard from 'src/components/guards/ManualApprovedGuard';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import TwoFactorAuthGuard from 'src/components/guards/TwoFactorAuthGuard';
import Iconify from 'src/components/iconify/Iconify';
import {
  accountSelector,
  brandingSelector,
  companySelector
} from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import {
  formatPhoneNumberToWhatsappLink,
  isSingleCampaignMode
} from 'src/utils/utils';
import EightCapGuard from '../../../components/guards/EightCapGuard';
import MlmGuard from '../../../components/guards/MlmGuard';
import userProfileImageSize from '../../../utils/enums/userProfileImageSize';
import getUserProfileImage from '../../../utils/filesUploadUtils/getUserProfileImage';
import NavItem from './NavItem';

const PREFIX = 'index';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  navbarLayout: `${PREFIX}-navbarLayout`,
  navbarFooter: `${PREFIX}-navbarFooter`,
  docksBox: `${PREFIX}-docksBox`,
  checkOurDocks: `${PREFIX}-checkOurDocks`,
  filterButton: `${PREFIX}-filterButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 245,
    top: 50,
    height: `calc(100% - 50px)`
  },

  [`& .${classes.avatar}`]: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },

  [`& .${classes.navbarLayout}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  [`& .${classes.navbarFooter}`]: {
    margin: '17px',
    paddingLeft: '21px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '7px'
  },

  [`& .${classes.docksBox}`]: {
    margin: '17px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },

  [`& .${classes.checkOurDocks}`]: {
    color: '#8492a6',
    padding: '7px 0px'
  }
}));

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            ...rest
          }),
        []
      )}
    </List>
  );
}

function getAllowedTypes(item) {
  const allowedTypesSet = new Set();

  for (const subItem of item.items) {
    if (subItem.allowedTypes === undefined) {
      //at least 1 go to def value
      return Object.values(SiteTypeEnum);
    } else {
      subItem.allowedTypes.forEach(type => allowedTypesSet.add(type));
    }
  }

  return [...allowedTypesSet];
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (!item) return acc;
  const key = item.title + depth;
  const open = matchPath(pathname, {
    path: item.href || (item.inlineIcon && item.inlineIcon.href),
    exact: false
  });
  if (item.items) {
    acc.push(
      <ScopeGuard key={key} scope={item?.scope}>
        <ManualApprovedGuard
          active={item?.menualApproved ?? false}
          showAlert={false}
        >
          <MlmGuard active={item?.mlmActive ?? false}>
            <EightCapGuard active={item?.eightCapGuard ?? false}>
              <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
                onClick={item.onClick}
                inlineIcon={item.inlineIcon}
                toolTipText={item.toolTipText}
                childItemsSize={item.items.length}
                id={item?.id}
                allowedTypes={getAllowedTypes(item)} // Set allowedTypes to include all allowed types from sub-items without duplicates
              >
                {renderNavItems({
                  depth: depth + 1,
                  pathname,
                  items: item.items
                })}
              </NavItem>
            </EightCapGuard>
          </MlmGuard>
        </ManualApprovedGuard>
      </ScopeGuard>
    );
  } else {
    acc.push(
      <ScopeGuard key={key} scope={item?.scope}>
        <ManualApprovedGuard
          active={item?.menualApproved ?? false}
          showAlert={false}
        >
          <MlmGuard active={item?.mlmActive ?? false}>
            <EightCapGuard active={item?.eightCapGuard ?? false}>
              <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                open={Boolean(open)}
                info={item.info}
                title={item.title}
                onClick={item.onClick}
                inlineIcon={item.inlineIcon}
                toolTipText={item.toolTipText}
                id={item?.id}
                allowedTypes={item?.allowedTypes}
              />
            </EightCapGuard>
          </MlmGuard>
        </ManualApprovedGuard>
      </ScopeGuard>
    );
  }

  return acc;
}

let counter = 0;

function NavBar({ openMobile, onMobileClose, intl }) {
  const brandnig = useSelector(brandingSelector);

  const location = useLocation();
  const { user, avatarChanged } = useSelector(accountSelector);
  const {
    singleCampaignMode,
    defaultCampaign,
    hidePPCCommission,
    hideCPMCommission
  } = useSelector(companySelector);

  const isSingleCampaign = isSingleCampaignMode(user, singleCampaignMode);

  const management = [
    {
      title: intl.formatMessage({
        id: isSingleCampaign ? 'campaign' : 'campaigns'
      }),
      icon: () => <Iconify icon="ic:outline-campaign" />,
      href: isSingleCampaign
        ? `/app/affiliate/tracking-link/${user?.defaultCampaign
            ?.campaignIdentity ?? defaultCampaign?.campaignIdentity}`
        : '/app/affiliate/campaigns',
      menualApproved: true,
      scope: UserPermissionsEnum.AFFILIATE_CAMPAIGN.value,
      eightCapGuard: true,
      id: 'navbar-campaigns'
    },
    {
      title: intl.formatMessage({ id: 'creatives' }),
      icon: () => <Iconify icon="mdi:art" />,
      href: `/app/affiliate/creatives`,
      menualApproved: true,
      id: 'navbar-creatives'
    },
    {
      title: intl.formatMessage({ id: 'statistics' }),
      icon: () => <Iconify icon="iconoir:stats-up-square" />,
      href: '/app/affiliate/statistics',
      eightCapGuard: true,
      id: 'navbar-performance',
      items: [
        {
          title: intl.formatMessage({ id: 'conversions' }),
          icon: () => (
            <Iconify icon="material-symbols:connect-without-contact" />
          ),
          href: '/app/affiliate/statistics/conversions',
          menualApproved: true,
          eightCapGuard: true,
          id: 'navbar-conversions',
          scope: UserPermissionsEnum.CONVERSIONS.value
        },
        {
          title: intl.formatMessage({ id: 'leads' }),
          icon: () => <Iconify icon="mdi:leads" />,
          href: '/app/affiliate/statistics/leads',
          id: 'navbar-leads',
          scope: UserPermissionsEnum.LEADS.value
        },
        {
          title: intl.formatMessage({ id: 'clicks' }) + ' (PPC)',
          icon: () => <Iconify icon="ic:baseline-ads-click" />,
          href: '/app/affiliate/statistics/clicks',
          menualApproved: true,
          id: 'navbar-clicks',
          scope: UserPermissionsEnum.CLICKS.value,
          delete: hidePPCCommission
        },
        {
          title: intl.formatMessage({ id: 'impressions' }) + ' (CPM)',
          icon: () => <Iconify icon="carbon:view" />,
          href: '/app/affiliate/statistics/impressions',
          menualApproved: true,
          id: 'navbar-impressions',
          scope: UserPermissionsEnum.IMPRESSIONS.value,
          delete: hideCPMCommission
        },
        {
          title: intl.formatMessage({ id: 'reports' }),
          icon: () => <Iconify icon="material-symbols:campaign" />,
          href: '/app/affiliate/statistics/campaigns/campaign-month',
          menualApproved: true,
          eightCapGuard: true,
          id: 'navbar-reports',
          scope: UserPermissionsEnum.CONVERSIONS.value
        },
        {
          title: intl.formatMessage({ id: 'igaming_reports' }),
          icon: () => <Iconify icon="material-symbols:campaign" />,
          href: '/app/affiliate/statistics/igaming-reports/campaign-month',
          scope: UserPermissionsEnum.CONVERSIONS.value,
          id: 'navbar-igaming-reports',
          allowedTypes: [SiteTypeEnum.IGAMING_NETWORK]
        }
      ].filter(item => !item?.delete),
      menualApproved: true
    },
    {
      title: intl.formatMessage({ id: 'forex' }),
      icon: () => <Iconify icon="ant-design:stock-outlined" />,
      href: '/app/management/finance',
      menualApproved: true,
      id: 'navbar-finance',
      scope: UserPermissionsEnum.FINANCIAL.value,
      items: [
        {
          title: intl.formatMessage({ id: 'users' }),
          icon: () => <Iconify icon="solar:users-group-two-rounded-bold" />,
          href: '/app/management/finance/users',
          allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO],
          menualApproved: true,
          id: 'navbar-finance-users',
          scope: UserPermissionsEnum.FINANCIAL_USERS.value
        },
        {
          title: intl.formatMessage({ id: 'accounts' }),
          icon: () => <Iconify icon="carbon:view" />,
          href: '/app/management/finance/accounts',
          allowedTypes: [SiteTypeEnum.BROKER],
          menualApproved: true,
          id: 'navbar-finance-accounts',
          scope: UserPermissionsEnum.FINANCIAL_ACCOUNTS.value
        },
        {
          title: intl.formatMessage({ id: 'transactions' }),
          icon: () => <Iconify icon="ic:baseline-ads-click" />,
          href: '/app/management/finance/transactions',
          allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO],
          menualApproved: true,
          id: 'navbar-finance-transactions',
          scope: UserPermissionsEnum.FINANCIAL_TRANSACTIONS.value
        },
        {
          title: intl.formatMessage({ id: 'reports' }),
          // beta: true,
          icon: () => <Iconify icon="material-symbols:campaign" />,
          allowedTypes: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO],
          href: '/app/affiliate/statistics/financial/campaign-month',
          menualApproved: true,
          id: 'navbar-finance-reports',
          scope: UserPermissionsEnum.FINANCIAL_REPORTS.value
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'referrals' }),
      icon: () => <Iconify icon="ph:users-four-light" />,
      href: '/app/affiliate/statistics/mlm',
      menualApproved: true,
      id: 'navbar-referrals',
      scope: UserPermissionsEnum.REFERRALS.value,
      mlmActive: true
    },
    {
      title: intl.formatMessage({ id: 'payouts' }),
      icon: () => (
        <Iconify icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance" />
      ),
      href: '/app/affiliate/payouts',
      menualApproved: true,
      id: 'navbarpayouts',
      eightCapGuard: true
    },
    {
      title: intl.formatMessage({ id: 'settings' }),
      icon: () => <Iconify icon="material-symbols:settings-outline" />,
      href: '/app/affiliate/settings',
      id: 'navbar-settings'
    },
    {
      title: intl.formatMessage({ id: 'contact_us' }),
      icon: () => <Iconify icon="pixelarticons:contact-plus" />,
      href: '/app/affiliate/contact-us',
      id: 'navbar-contact-us'
    }
  ];

  const navConfig = [
    {
      // subheader: intl.formatMessage({ id: 'home' }),
      items: [
        brandnig?.customHomePage
          ? {
              title: intl.formatMessage({ id: 'home' }),
              icon: () => <Iconify icon="ic:outline-home" />,
              href: '/app/home',
              id: 'navbar-home'
            }
          : null,
        {
          title: intl.formatMessage({ id: 'dashboard' }),
          icon: () => <Iconify icon="material-symbols:dashboard-outline" />,
          href: '/app/reports/dashboard',
          id: 'navbar-dashboard'
        },

        {
          title: intl.formatMessage({ id: 'news' }),
          icon: () => <Iconify icon="fluent:news-20-regular" />,
          href: '/app/news',
          id: 'navbar-news'
        },
        ...management
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const [imgUrl, setImgUrl] = useState('');

  const updateUserImage = async () => {
    await getUserProfileImage(user, userProfileImageSize.medium, setImgUrl);
  };

  if (avatarChanged && counter < 1) {
    updateUserImage();
    counter++;
  }
  if (user.profileImage === null) {
    if (imgUrl !== '') {
      setImgUrl('');
    }
  }

  const contactInformation = user?.manager?.contactInformation;

  const ManagerInfo = (
    <Box p={1}>
      <Box
        gap={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent="center"
        alignItems={'center'}
        sx={{ flexWrap: 'wrap', width: '100%' }}
      >
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="your_manager" />
        </Typography>{' '}
        {!!user?.manager?.profileImage && (
          <Avatar
            sx={{ alignSelf: 'center', width: 56, height: 56 }}
            src={
              process.env.REACT_APP_PROFILE_PIC_BASE_URL +
              user?.manager?.profileImage
            }
          />
        )}
        <Typography variant="h5" color="textPrimary">
          {user?.manager?.fullName || '-'}
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          sx={{
            gap: 1,
            width: '100%',
            flexWrap: 'wrap',
            wordBreak: 'break-word'
          }}
        >
          <ContactLink
            icon="ic:outline-email"
            href={`mailto:${contactInformation?.email}`}
            label={contactInformation?.email}
          />
          <ContactLink
            icon="ic:outline-phone"
            href={`tel:${contactInformation?.phone}`}
            label={contactInformation?.phone}
          />

          <ContactLink
            icon="teenyicons:skype-outline"
            href={`skype:${contactInformation?.skype}?chat`}
            label={contactInformation?.skype}
          />

          <ContactLink
            icon="ic:round-whatsapp"
            href={formatPhoneNumberToWhatsappLink(contactInformation?.whatsapp)}
            label={contactInformation?.whatsapp}
            target="_blank"
          />

          <ContactLink
            icon="hugeicons:wechat"
            href={`weixin://dl/chat?${contactInformation?.weChat}`}
            label={contactInformation?.weChat}
          />
        </Box>
      </Box>
    </Box>
  );
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box className={classes.navbarLayout}>
          <Box p={1} justifyContent="flex-start">
            <TwoFactorAuthGuard hide>
              {navConfig.map((config, i) => (
                <List
                  key={i}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                      {config.inlineIcon && (
                        <Tooltip placement="top" title="Add Team">
                          <IconButton
                            onClick={handleTeamAddClick}
                            style={{ float: 'right' }}
                            size="large"
                          >
                            <config.inlineIcon.icon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
              <Divider />
            </TwoFactorAuthGuard>
            {ManagerInfo}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          onClose={onMobileClose}
          open={openMobile}
          sx={{ zIndex: 1500 }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden lgDown>
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Root>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default injectIntl(NavBar);
