// eslint-disable-next-line import/prefer-default-export
import moment from 'moment-timezone';
import 'moment/locale/he';
import { store } from 'src';
import countryMap from '../assets/json/countryISO.json';
import { userRole } from '../config/userConfig';
import { THEMES } from '../constants';
import ajaxUtils from './ajaxUtils';
import { post } from './axios';
import { TimePeriodEnum } from './enums/ConversionEnums';
import { CustomFieldTypeEnum } from './enums/SettingEnums';
import { campaignSettings } from './enums/campaignsEnums';
import { DATE_DEFUALT_FORMAT } from './staticObjects';

var currentTimeZone = moment.tz.guess();

export const rebuildCampaignGeneralSettingsData = (data, file) => {
  const formData = new FormData();
  formData.append(
    'campaignGeneralSettings',
    new Blob([JSON.stringify(data)], {
      type: 'application/json'
    })
  );
  formData.append('file', file);
  return formData;
};

// eslint-disable-next-line import/prefer-default-export
export const rebuildCampaignData = (data, file) => {
  const campaignSettingsDto = {
    campaignGeneralSettingsDto: {
      title: data.generalSettingsTitle,
      description: data.generalSettingsDescription,
      advertiserOid: data.generalSettingsAdvertiserOid,
      campaignTrafficSources: data.generalSettingsCampaignTrafficSources,
      logoUrl: data.generalSettingsLogoUrl,
      currency: data.generalSettingsCurrency,
      status: data.generalSettingsStatus,
      privacy: data.generalSettingsPrivacy,
      featured: data.generalSettingsFeatured,
      startDate: new Date(data.generalSettingsStartDate),
      endDate: new Date(data.generalSettingsEndDate)
      // holdTime: data.generalSettingsHoldTime
    },
    campaignTrackingSettingsDto: {
      trackingUrl: data.trackingSettingsTrackingUrl,
      previewUrl: data.trackingSettingsPreviewUrl,
      redirectType: data.trackingSettingsRedirectType,
      trafficBackUrl: data.trackingSettingsTrafficBackUrl,
      allowedIps: data.trackingSettingsAllowedIps,
      uniqueIpOnly: data.trackingSettingsUniqueIpOnly,
      integration: {
        s2sPostback: data.trackingSettingsIntegrationS2sPostback,
        image: data.trackingSettingsIntegrationImage,
        js: data.trackingSettingsIntegrationJs,
        iframe: data.trackingSettingsIntegrationIframe
      }
    },
    campaignPayoutsSettingsDtoList: []
  };
  for (let i = 0; i < data[campaignSettings.PAYOUTS].length; i++) {
    campaignSettingsDto.campaignPayoutsSettingsDtoList[i] = {
      identity: data[campaignSettings.PAYOUTS][i].payoutsIdentity,
      revenue: data[campaignSettings.PAYOUTS][i].payoutsSettingsRevenue,
      payouts: data[campaignSettings.PAYOUTS][i].payoutsSettingsPayouts,
      paymentType: Number(
        data[campaignSettings.PAYOUTS][i].payoutsSettingsPaymentType
      ),
      payoutType: Number(
        data[campaignSettings.PAYOUTS][i].payoutsSettingsPayoutType
      ),
      currency: data[campaignSettings.PAYOUTS][i].payoutsSettingsCurrency,
      goalTitle: data[campaignSettings.PAYOUTS][i].payoutsSettingsGoalTitle,
      advertisers: data[campaignSettings.PAYOUTS][i].payoutsSettingsAdvertisers
    };
  }

  const formData = new FormData();
  formData.append(
    'campaignSettingsDto',
    new Blob([JSON.stringify(campaignSettingsDto)], {
      type: 'application/json'
    })
  );
  formData.append('file', file);
  return formData;
};

const extractNumberStringFromBoolean = boolean => {
  if (boolean === true) {
    return '1';
  }
  return '0';
};

export const rebuildRegistrationDto = data => {
  let userRegDto = { role: userRole.NORMAL_USER };
  userRegDto.company = { name: data.companyName };
  userRegDto = { ...userRegDto, ...data };
  delete userRegDto.companyName;
  userRegDto.customFields = data.customFields.map(customField => ({
    customFieldConfigOid: customField?.customFieldIdentity,
    value:
      customField?.customFieldTypeOid === CustomFieldTypeEnum.CHECK_BOX.oid
        ? extractNumberStringFromBoolean(customField?.customFieldValue)
        : customField?.customFieldValue
  }));
  userRegDto.refCode = data.refCode;
  userRegDto.managerSerialOid = data?.managerSerialOid;
  userRegDto.agreedPromotionalMaterial = data?.agreedPromotionalMaterial;
  userRegDto.agreedTermsAndConditions = data?.policy;
  userRegDto.agreedPrivacyPolicy = data?.policy;
  userRegDto.campaignSerialOid = data?.campaignSerialOid;
  return userRegDto;
};

export const rebuildAffiliateGeneralSettingsDto = data => ({
  firstName: data?.firstName,
  lastName: data?.lastName,
  phoneNumber: data?.phoneNumber,
  siteThemeOid: THEMES[data.theme].oid,
  languageOid: Number(data.lang),
  timeZone: data?.timeZone,
  timeFormat: data?.timeFormat,
  affiliateCompanyName: data?.affiliateCompanyName,
  taxId: data?.taxId
});

export const getTimeDurationFromTimePeriod = timeDurationId => {
  const duration = {};
  // eslint-disable-next-line default-case
  switch (timeDurationId) {
    case TimePeriodEnum.TODAY.value:
      duration.days = 1;
      break;
    case TimePeriodEnum.LAST_WEEK.value:
      duration.weeks = 1;
      break;
    case TimePeriodEnum.LAST_TWO_WEEKS.value:
      duration.weeks = 2;
      break;
    case TimePeriodEnum.LAST_MONTH.value:
      duration.months = 1;
      break;
    case TimePeriodEnum.LAST_THREE_MONTHS.value:
      duration.months = 3;
      break;
    case TimePeriodEnum.LAST_SIX_MONTHS.value:
      duration.months = 6;
      break;
    case TimePeriodEnum.LAST_YEAR.value:
      duration.years = 1;
      break;
  }
  return duration;
};

export const dateToLocalDateTime = jsDate => {
  if (!jsDate) return null;
  try {
    return jsDate.clone().toISOString();
  } catch (e) {
    return new Date(jsDate)
      .toISOString()
      .substring(0, 19)
      .replace(' ', 'T');
  }
};
export const dateToLocalDate = jsDate =>
  new Date(jsDate).toISOString().substring(0, 10);
export const localDateTimeToDate = javaDate => javaDate.replace('T', ' ');

export const getCountryName = countryCode => {
  return countryMap?.[countryCode?.toString()?.toUpperCase()];
};

export const openDocs = () => {
  const domainName = store.getState()?.config?.domain?.name;
  window
    .open(`https://${domainName}-api.tracknow.io/docs/api-docs`, '_blank')
    .focus();
};

export const openDocsV2 = () => {
  window.open(`https://api.tracknow.io/docs/api-docs`, '_blank').focus();
};

export const setMomentDefualts = (tz, format, lang) => {
  if (tz == null) tz = moment.tz.guess();
  const newFormat = format ?? DATE_DEFUALT_FORMAT;
  moment.defaultFormat = newFormat;
  moment.defaultFormatUtc = newFormat;
  moment.tz.setDefault(tz);
  moment.locale(lang == 'iw' ? 'he' : lang ?? 'en');
  currentTimeZone = tz;
};

export const formatUtcDate = date => {
  return moment
    .utc(date)
    .tz(currentTimeZone)
    .format();
};

export const getUtcDate = date => {
  return moment.utc(date).tz(currentTimeZone);
};

export const humenizeUtcDate = date => {
  return moment
    .utc(date)
    .tz(currentTimeZone)
    .fromNow();
};

export const copyToClipboard = (text, enqueueSnackbar) => {
  navigator.clipboard.writeText(text);
  if (enqueueSnackbar) enqueueSnackbar('Copied to Clipboard');
};

export function saveWithExpiration(key, value, expirationDate) {
  const item = {
    value: value,
    expirationDate: expirationDate.getTime() // Store expiration date as milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to retrieve a value from local storage and check expiration
export function getWithExpiration(key) {
  const item = localStorage.getItem(key);
  if (item) {
    const parsedItem = JSON.parse(item);
    if (parsedItem.expirationDate >= Date.now()) {
      return parsedItem.value;
    } else {
      // Item has expired, remove it from storage
      localStorage.removeItem(key);
    }
  }
  return null; // Return null if key doesn't exist or has expired
}

export const convertDataGridFilterToV6 = filter => {
  return JSON.stringify({
    id: filter?.id,
    field: filter?.columnField,
    operator: filter?.operatorValue,
    value: filter?.value
  });
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export const isEmptyHtml = str => {
  if (!str) return true;
  str = str.replace('\n', '');
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  const elements = doc.body.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].textContent.trim()) {
      return false;
    }
  }

  return true;
};

export const isImage = filename => {
  const imagePattern = /\.(png|jpeg|jpg|gif)$/i;

  // Check if the filename matches the pattern
  return imagePattern.test(filename);
};

export const shortUrl = async fullUrl => {
  const response = await post(ajaxUtils.shortUrl, { fullUrl });
  return response?.data;
};
export const getVisibilityModel = columns =>
  columns.reduce((acc, column) => {
    // Initialize the visibility state of each column based on the 'hide' property
    acc[column.field] = !column.hide;
    return acc;
  }, {});

export function isURL(str) {
  // Trim leading and trailing whitespaces
  var trimmedStr = str.trim();
  // Regular expression for a basic URL validation, adjusted to match the whole string
  var urlRegex = /^\b(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]$/gm;

  return urlRegex.test(trimmedStr);
}

export const mergeTranslations = (defaultLanguage, otherLanguages) => {
  //assuming en is def allways
  const mergedTranslations = { en: defaultLanguage };

  // Iterate through keys in enTranslations
  Object.keys(defaultLanguage).forEach(key => {
    // Iterate through each language
    Object.keys(otherLanguages).forEach(lang => {
      // Initialize the language in the result object
      if (!mergedTranslations[lang]) {
        mergedTranslations[lang] = {};
      }

      // If the key doesn't exist in the language, use the English translation
      mergedTranslations[lang][key] =
        otherLanguages[lang][key] || defaultLanguage[key];
    });
  });

  return mergedTranslations;
};

export const replaceTranslations = (mergedTranslations, replaceKeys) => {
  // Iterate through each language
  Object.keys(replaceKeys).forEach(lang => {
    // Initialize the language in the result object
    if (!mergedTranslations[lang]) {
      mergedTranslations[lang] = {};
      // missing[lang] = {};
    }

    // If the key doesn't exist in the language, use the English translation
    Object.keys(replaceKeys[lang]).forEach(key => {
      if (lang == 'en') {
        //in defualt lang we replace all langs to prevent missing keys in spacial translations then if they exists in replaceKeys of the corresponding lang thy will be modified again
        Object.keys(mergedTranslations).forEach(
          tempLang =>
            (mergedTranslations[tempLang][key] = replaceKeys[lang][key])
        );
      } else {
        mergedTranslations[lang][key] = replaceKeys[lang][key];
      }
    });
  });
  return mergedTranslations;
};

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export const displayUpdateSnackbar = (
  enqueueSnackbar,
  intl,
  sucssess,
  customMessage = null
) => {
  enqueueSnackbar(
    intl.formatMessage({
      id: sucssess ? 'successfully_updated' : customMessage ?? 'general_error'
    }),
    {
      variant: sucssess ? 'success' : 'error',
      autoHideDuration: 2200
    }
  );
};

export const calculateComplementaryColor = hexColor => {
  // Remove the # symbol if it's present
  hexColor = hexColor.replace('#', '');

  // Convert the hexadecimal color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the luminance of the original color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Choose black or white as the complementary color based on luminance
  const complementaryColor = luminance > 128 ? '#000000' : '#ffffff';

  return complementaryColor;
};

export const getRandomNumberBetween = (min = 0, max = 10) => {
  return min + Math.floor(Math.random() * (max - min));
};

export function getHostname(url) {
  // Create a URL object
  try {
    var parsedURL = new URL(url);
    // Return the hostname
    return parsedURL.hostname;
  } catch (error) {
    return '';
  }
}

export const rebuildFileForm = (data, paramName = 'brandLogo') => {
  const formData = new FormData();
  formData.append(paramName, data?.[paramName]);
  return formData;
};

export const formatPhoneNumberToWhatsappLink = phoneNumber => {
  if (!phoneNumber) return null;
  // Remove all non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Ensure it starts with the country code, e.g., 55 for Brazil
  const formatted = `https://wa.me/${cleaned}`;

  return formatted;
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const downloadQR = () => {
  const canvas = document.getElementById('qr');
  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream');
  let downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = 'QR.png';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const updateUrlWithCustomPathAndCreativeId = (
  url,
  customUrl,
  creativeId,
  goal,
  sub1
) => {
  let updatedUrl = url;

  if (customUrl) {
    if (url.includes('path=')) {
      updatedUrl = url.replace(
        /(\bpath=)([^&]+)/,
        `$1${encodeURIComponent(customUrl)}`
      );
    } else {
      const separator = url.includes('?') ? '&' : '?';
      updatedUrl = `${url}${separator}path=${encodeURIComponent(customUrl)}`;
    }
  }
  if (goal) {
    const separator = updatedUrl.includes('?') ? '&' : '?';
    updatedUrl = `${updatedUrl}${separator}goal=${encodeURIComponent(goal)}`;
  }
  if (sub1) {
    const separator = updatedUrl.includes('?') ? '&' : '?';
    updatedUrl = `${updatedUrl}${separator}sub1=${encodeURIComponent(sub1)}`;
  }

  return `${updatedUrl}&creative_Id=${creativeId}`;
};

export const formatNumberCompact = (value, maximumFractionDigits = 2) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2
  }).format(value);

export const getPaginatedData = (data, currentPage, itemsPerPage) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return data ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
};

export const applyPagingParamsSort = (sort, params) => {
  if (sort) {
    params[`sort`] = `${sort?.field},${sort?.sort}`;
  }
};

export const addParamsToUrl = (url, params) => {
  if (!url) return null;
  if (!params || params.length === 0) return url;

  const queryString = params
    .map(param => {
      return Object.entries(param)
        .filter(([, value]) => value !== null)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
    })
    .join('&');

  const separator = url.includes('?') ? '&' : '?';

  return queryString ? `${url}${separator}${queryString}` : url;
};

export const isSingleCampaignMode = (affiliate, singleCampaignMode) => {
  if (!affiliate) return false;
  const isSingleModeByDefault =
    singleCampaignMode && affiliate?.userDefaultCampaignEnum !== 'MULTIPLE';

  return affiliate?.singleCampaignModeOverride === null
    ? isSingleModeByDefault
    : affiliate?.singleCampaignModeOverride;
};

export const addMacrosToUrl = (url, macros = {}) => {
  if (!url) return;

  Object.keys(macros).forEach(key => {
    const regex = new RegExp(`{${key}}`, 'g');
    url = url.replace(regex, macros[key]);
  });

  return url;
};

export const handleDayTime = (date, isStartDate, isEndDate) => {
  if (!date) return null;
  if (isEndDate) {
    return date.clone().endOf('day');
  } else if (isStartDate) {
    return date.clone().startOf('day');
  }
  return date;
};

export function stringToHSL(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  const s = 75 + (hash % 25); // Saturation between 75% and 100%
  const l = 50 + (hash % 20); // Lightness between 50% and 70%
  return `hsl(${h}, ${s}%, ${l}%)`;
}

export const formatGroupByNavBar = (
  item,
  parentValue = '',
  handleChange,
  groupBy,
  intl
) => ({
  label: intl.formatMessage({ id: item?.key }),
  icon: item?.icon ?? '',
  onClick: e =>
    handleChange(
      e,
      parentValue ? `${parentValue}-${item?.value}` : item?.value
    ),
  value: parentValue ? `${parentValue}-${item?.value}` : item?.value,
  scope: item?.scope,
  socialOnly: !!item?.socialMode,
  initialExpandState: groupBy?.includes(item?.value + '-'),
  siteTypes: item?.siteType,
  children: item?.children
    ? Object.values(item.children).map(child =>
        formatGroupByNavBar(child, item?.value, handleChange, groupBy, intl)
      )
    : undefined
});

export const removeUneccecerySlashFromURL = url => {
  return url.replace(/([^:]\/)\/+/g, '$1');
};

export function stripTimeFormat(format) {
  return format.replace(/[\s,]*HH(:mm(:ss)?)?/, '').trim();
}

export function handleTimeInFormat(userDateTimeFormat, stripTime = false) {
  if (!userDateTimeFormat) return handleTimeInFormat(DEFAULT_DATE_TIME_FORMAT);
  if (stripTime) return stripTimeFormat(userDateTimeFormat);
  // Return early if time format already exists
  if (/[hH]{1,2}/.test(userDateTimeFormat)) {
    return userDateTimeFormat;
  }
  // Detect US (Month first) vs. International (Day first or ISO format)
  const isUSFormat = /^(MM|M)/.test(userDateTimeFormat); // US-style detected
  // US formats prefer 12-hour, international formats (ISO) prefer 24-hour
  return isUSFormat
    ? `${userDateTimeFormat} hh:mm A` // US → 12-hour format
    : `${userDateTimeFormat} HH:mm`; // International or ISO → 24-hour format
}

export const replaceDomain = (url, newDomain) => {
  return url.replace(/:\/\/([^/]+)/, `://${newDomain}`);
};

export function isWhiteOrBright(color) {
  let r, g, b;

  if (color.startsWith('#')) {
    // Convert hex to RGB
    if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16);
      g = parseInt(color[2] + color[2], 16);
      b = parseInt(color[3] + color[3], 16);
    } else if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else {
      throw new Error('Invalid hex color format');
    }
  } else if (color.startsWith('rgb')) {
    // Extract RGB values
    const match = color.match(/\d+/g);
    if (!match || match.length < 3) {
      throw new Error('Invalid RGB color format');
    }
    [r, g, b] = match.map(Number);
  } else {
    throw new Error('Unsupported color format');
  }

  // Calculate relative luminance (perceived brightness)
  const brightness = r * 0.299 + g * 0.587 + b * 0.114;

  // Consider white or very bright if brightness is above a threshold (e.g., 200)
  return brightness > 200;
}

export const getComplementaryColorIfBright = color => {
  if (isWhiteOrBright(color)) {
    return calculateComplementaryColor(color);
  }
  return color;
};
