/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  COMPANY_ADVERTISERS,
  COMPANY_BRANDING,
  COMPANY_CURRENCY,
  COMPANY_INITIAL_DATA,
  COMPANY_LOGO_OVERRIDE,
  COMPANY_MERCHANT,
  COMPANY_PAYMENT_METHODS,
  COMPANY_TRAFFIC_SOURCE
} from 'src/actions/companyActions';
import { LOGOUT } from 'src/actions/userActions';
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';

const initialState = {
  trafficSources: [],
  advertisers: [],
  merchant: {},
  currency: null,
  branding: null,
  companyLogoOverride: null,
  minimumWithdrawalAmount: 0,
  mlmActive: false,
  countryCodeRequired: false,
  affiliateNeedsManualActivation: false,
  mlmPercent: null,
  mlmLevel2Active: false,
  mlmLevel2Percent: null,
  mlmLevel3Active: false,
  mlmLevel3Percent: null,
  mlmLevel4Active: false,
  mlmLevel4Percent: null,
  mlmLevel5Active: false,
  mlmLevel5Percent: null,
  mlmLevel6Active: false,
  mlmLevel6Percent: null,
  mlmLevel7Active: false,
  mlmLevel7Percent: null,
  mlmLevel8Active: false,
  mlmLevel8Percent: null,
  mlmLevel9Active: false,
  mlmLevel9Percent: null,
  mlmLevel10Active: false,
  mlmLevel10Percent: null,
  defaultCampaign: null,
  singleCampaignMode: false,
  hideAmount: false,
  hideShortLink: false,
  displayConversionPersonalInfo: false,
  brokerMode: false,
  requireAffiliateLegalStatus: false,
  requireAffiliate2FA: false,
  requiredCompanyName: true,
  displayAccessibilityToolbox: false,
  hideOrderId: false,
  hideFooter: true,
  customFooter: null,
  allowAffiliateTrackingDomains: false,
  paymentMethods: [],
  displayRegistrationPopup: false,
  contactUsEmail: null,
  socialMode: false,
  siteType: SiteTypeEnum.GENERAL,
  oid: null,
  companyDefaultLanguage: 'EN',
  customCurrencyPlacement: null,
  customCurrencySymbol: null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_TRAFFIC_SOURCE: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.trafficSources = action.payload;
      });
    }
    case COMPANY_PAYMENT_METHODS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.paymentMethods = action.payload;
      });
    }
    case COMPANY_ADVERTISERS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.advertisers = action.payload;
      });
    }
    case COMPANY_MERCHANT: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.merchant = action.payload;
      });
    }
    case COMPANY_CURRENCY: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload;
      });
    }
    case COMPANY_BRANDING: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.branding = action.payload;
      });
    }
    case COMPANY_LOGO_OVERRIDE: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.companyLogoOverride = action.payload;
      });
    }
    case COMPANY_INITIAL_DATA: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload.currency;
        draft.trafficSources = action.payload.companyTrafficSources;
        draft.advertisers = action.payload.companyAdvertisers;
        draft.minimumWithdrawalAmount = action.payload.minimumWithdrawalAmount;
        draft.mlmActive = action.payload.mlmActive;
        draft.mlmPercent = action.payload.mlmPercent;
        draft.mlmLevel2Active = action.payload.mlmLevel2Active;
        draft.mlmLevel2Percent = action.payload.mlmLevel2Percent;
        draft.mlmLevel3Active = action.payload.mlmLevel3Active;
        draft.mlmLevel3Percent = action.payload.mlmLevel3Percent;
        draft.mlmLevel4Active = action.payload.mlmLevel4Active;
        draft.mlmLevel4Percent = action.payload.mlmLevel4Percent;
        draft.mlmLevel5Active = action.payload.mlmLevel5Active;
        draft.mlmLevel5Percent = action.payload.mlmLevel5Percent;
        draft.mlmLevel6Active = action.payload.mlmLevel6Active;
        draft.mlmLevel6Percent = action.payload.mlmLevel6Percent;
        draft.mlmLevel7Active = action.payload.mlmLevel7Active;
        draft.mlmLevel7Percent = action.payload.mlmLevel7Percent;
        draft.mlmLevel8Active = action.payload.mlmLevel8Active;
        draft.mlmLevel8Percent = action.payload.mlmLevel8Percent;
        draft.mlmLevel9Active = action.payload.mlmLevel9Active;
        draft.mlmLevel9Percent = action.payload.mlmLevel9Percent;
        draft.mlmLevel10Active = action.payload.mlmLevel10Active;
        draft.mlmLevel10Percent = action.payload.mlmLevel10Percent;
        draft.defaultCampaign = action.payload.defaultCampaign;
        draft.countryCodeRequired = action.payload.countryCodeRequired;
        draft.affiliatePromotionalMaterial =
          action.payload.affiliatePromotionalMaterial;
        draft.hideFooter = action.payload.hideFooter;
        draft.customFooter = action.payload.customFooter;
        draft.affiliateNeedsManualActivation =
          action.payload.affiliateNeedsManualActivation;
        draft.singleCampaignMode = action.payload.singleCampaignMode;
        draft.displayAccessibilityToolbox =
          action.payload.displayAccessibilityToolbox;
        draft.hideAmount = action.payload.hideAmount;
        draft.hideShortLink = action.payload.hideShortLink;
        draft.displayConversionPersonalInfo =
          action.payload.displayConversionPersonalInfo;
        draft.hideOrderId = action.payload.hideOrderId;
        draft.brokerMode = action.payload.brokerMode;
        draft.siteType = action.payload.siteType;
        draft.oid = action.payload.oid;
        draft.requireAffiliateLegalStatus =
          action.payload.requireAffiliateLegalStatus;
        draft.requireAffiliate2FA = action.payload.requireAffiliate2FA;
        draft.requireCompanyName = action.payload.requireCompanyName;
        draft.displayCustomIdPicker = action.payload.displayCustomIdPicker;
        draft.removeAffiliateWithdrawalRequest =
          action.payload.removeAffiliateWithdrawalRequest;
        draft.supportAffiliatePayoutRequestInvoiceAttachment =
          action.payload.supportAffiliatePayoutRequestInvoiceAttachment;
        draft.hidePPCCommission = action.payload.hidePPCCommission;
        draft.hideCPMCommission = action.payload.hideCPMCommission;
        draft.displayRegistrationPopup =
          action.payload.displayRegistrationPopup;
        draft.contactUsEmail = action.payload.contactUsEmail;
        draft.supportAffiliatePayoutRequestCustomAmount =
          action.payload.supportAffiliatePayoutRequestCustomAmount;
        draft.affiliatePayoutType = action.payload.affiliatePayoutType;
        draft.companyDefaultLanguage = action.payload.companyDefaultLanguage;
        draft.companyGridControl = action.payload.companyGridControl;
        draft.customCurrencySymbol = action.payload.customCurrencySymbol;
        draft.customCurrencyPlacement = action.payload.customCurrencyPlacement;
        draft.allowAffiliateTrackingDomains =
          action.payload.allowAffiliateTrackingDomains;
      });
    }
    case LOGOUT: {
      return {
        ...initialState,
        companyLogoOverride: state.companyLogoOverride,
        companyDefaultLanguage: state.companyDefaultLanguage,
        branding: state.branding
      };
    }

    default: {
      return state;
    }
  }
};

export default companyReducer;
