/* eslint-disable react/no-array-index-key */
import { Fragment, lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AdminLoginView from 'src/views/auth/AdminLoginView';
import LoginView from 'src/views/auth/LoginView';
import { getCompanyInitialData } from './actions/configActions';
import DomainLoginGuard from './components/guards/DomainLoginGuard';
import DomainRegisterGuard from './components/guards/DomainRegisterGuard';
import ManualApprovedGuard from './components/guards/ManualApprovedGuard';
import { brandingSelector, domainSelector } from './selectors/selectors';

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const brandnig = useSelector(brandingSelector);

  const routesConfig = useMemo(
    () => [
      {
        exact: true,
        path: '/',
        component: () => (
          <Redirect
            to={
              brandnig?.customHomePage ? '/app/home' : '/app/reports/dashboard'
            }
          />
        )
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
      },
      {
        exact: true,
        path: '/invalidDomain',
        component: lazy(() => import('src/views/pages/InvalidDomain'))
      },
      {
        path: '/reset',
        exact: true,
        component: lazy(() =>
          import('src/utils/pageRouteUtils/ajaxRequestRouter')
        )
      },
      {
        path: '/activate',
        exact: true,
        component: lazy(() =>
          import('src/utils/pageRouteUtils/ajaxRequestRouter')
        )
      },
      {
        path: '/activationFailure',
        component: lazy(() =>
          import('src/views/auth/Activation/ActivationFailure')
        )
      },
      {
        path: '/changePassword',
        exact: true,
        component: lazy(() => import('src/views/auth/ChangePassword'))
      },
      {
        exact: true,
        guard: DomainLoginGuard,
        path: '/login',
        component: LoginView
      },
      {
        exact: true,
        path: '/admin-login/:accessToken',
        component: AdminLoginView
      },
      {
        exact: true,
        guard: DomainRegisterGuard,
        path: '/register',
        component: lazy(() =>
          import(
            'src/views/auth/RegisterView/affiliate/affiliateRegisterScreen'
          )
        )
      },
      {
        exact: true,
        guard: DomainRegisterGuard,
        path: '/registration',
        component: lazy(() =>
          import(
            'src/views/auth/RegisterView/affiliate/affiliateRegisterScreen'
          )
        )
      },
      {
        path: '/changePassword/:token',
        component: lazy(() => import('src/views/auth/ChangePassword'))
      },
      {
        exact: true,

        path: '/unsubscribe',
        component: lazy(() => import('src/views/auth/Unsubscribe'))
      },
      {
        exact: true,
        path: '/forgotPassword',
        component: lazy(() => import('src/views/auth/ForgotPassword'))
      },
      {
        exact: true,
        path: '/app/activation/:accessToken',
        component: lazy(() =>
          import('src/views/auth/ActivatedAffiliateView/index')
        )
      },
      {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
          {
            exact: true,
            path: '/app/home',
            component: lazy(() => import('src/views/pages/CustomHome.js'))
          },
          {
            exact: true,
            guard: ManualApprovedGuard,
            path: '/app/management/finance/users',
            component: lazy(() =>
              import(
                'src/views/affiliate/StatisticsListView/ForexRegistretionTable'
              )
            )
          },
          {
            exact: true,
            guard: ManualApprovedGuard,
            path: '/app/management/finance/accounts',
            component: lazy(() =>
              import(
                'src/views/affiliate/StatisticsListView//forex/ForexAccountsTable'
              )
            )
          },
          {
            exact: true,
            guard: ManualApprovedGuard,
            path: '/app/management/finance/transactions',
            component: lazy(() =>
              import(
                'src/views/affiliate/StatisticsListView//forex/ForexTransactionsTable'
              )
            )
          },
          {
            guard: ManualApprovedGuard,

            exact: true,
            path: '/app/management/finance/user/:id/manage',
            component: lazy(() =>
              import(
                'src/views/affiliate/BrokerManagement/BrokerManagementPage'
              )
            )
          },
          {
            guard: ManualApprovedGuard,

            exact: true,
            path: '/app/management/finance/user/:id/manage/:tab',
            component: lazy(() =>
              import(
                'src/views/affiliate/BrokerManagement/BrokerManagementPage'
              )
            )
          },
          {
            guard: ManualApprovedGuard,

            exact: true,
            path: '/app/affiliate/campaigns/add',
            component: lazy(() =>
              import(
                'src/views/affiliate/campaigns/CampaignEditOCreateView/index'
              )
            )
          },
          {
            exact: true,
            path: '/app/news',
            component: lazy(() => import('src/views/pages/NewsPage'))
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/campaigns/:campaignSerialOid',
            component: lazy(() =>
              import(
                'src/views/affiliate/campaigns/CampaignEditOCreateView/index'
              )
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/campaigns',
            component: lazy(() =>
              import(
                'src/views/affiliate/campaigns/CampaignsListView/Campaigns'
              )
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/mlm',
            component: lazy(() => import('src/views/affiliate/MlmUsers.js'))
          },
          {
            exact: true,
            path: '/app/affiliate/settings',
            component: lazy(() => import('src/views/affiliate/Setting'))
          },
          {
            exact: true,
            path: '/app/affiliate/contact-us',
            component: lazy(() => import('src/views/affiliate/ContactUs'))
          },
          {
            exact: true,
            path: '/app',
            component: () => (
              <Redirect
                to={
                  brandnig?.customHomePage
                    ? '/app/home'
                    : '/app/reports/dashboard'
                }
              />
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/reports/dashboard',
            component: lazy(() =>
              import('src/views/affiliate/DashboardAlternativeView')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/advertisers/:advertiserOid/edit',
            component: lazy(() =>
              import('src/views/affiliate/advertisers/AdvertiserEditView')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/tracking-link/:campaignSerialOid',
            component: lazy(() => import('src/views/affiliate/trackingLink'))
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/creatives',
            component: lazy(() =>
              import('src/views/affiliate/trackingLink/CreativesPage')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/campaigns',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/campaigns')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/campaigns/:groupBy',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/campaigns')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/igaming-reports',
            component: lazy(() =>
              import(
                'src/views/affiliate/StatisticsListView/Igaming/IGamingReportsPage'
              )
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/igaming-reports/:groupBy',
            component: lazy(() =>
              import(
                'src/views/affiliate/StatisticsListView/Igaming/IGamingReportsPage'
              )
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/financial',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/financial')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/financial/:groupBy',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/financial')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/clicks',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/clicks')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/impressions',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/impressions')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/conversions',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/conversions')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path:
              '/app/affiliate/statistics/conversions/by-campaign/:campaignSerialOid',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/conversions')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/statistics/leads',
            component: lazy(() =>
              import('src/views/affiliate/StatisticsListView/Leads')
            )
          },
          {
            guard: ManualApprovedGuard,
            exact: true,
            path: '/app/affiliate/payouts',
            component: lazy(() =>
              import('src/views/affiliate/Payouts/PayoutsListView')
            )
          },
          {
            exact: true,
            path: '/404',
            component: lazy(() => import('src/Error404View'))
          },
          {
            component: () => <Redirect to="/404" />
          }
        ]
      },
      {
        path: '*',
        layout: MainLayout,
        routes: [
          {
            component: () => <Redirect to="/404" />
          }
        ]
      }
    ],
    [brandnig?.customHomePage]
  );

  const domain = useSelector(domainSelector);

  if (!domain || !domain?.domainType) {
    getCompanyInitialData();
  }

  return renderRoutes(routesConfig);
}

export default Routes;
